import * as singleSpa from 'single-spa'
import { constructApplications, constructRoutes, constructLayoutEngine } from 'single-spa-layout'
import i18n from './core/i18n'

import * as Sentry from '@sentry/browser'
import { Integrations } from '@sentry/tracing'

import microfrontendLayout from './microfrontend-layout.html'

import errorDefault from './html/error-default.html'
import errorBrowser from './html/error-browser.html'
import loaderMain from './html/loader-main.html'
import { PapertrailSession } from '@papertrail/web3-session'
import { getAccountId, isSecurePath } from './core/utils'

Sentry.init({
  dsn: appConfig.SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: appConfig.SENTRY_SAMPLE_RATE,
  environment: appConfig.ENV,

  ignoreErrors: [
    //See W3-93
    'Non-Error exception captured',
    'Non-Error promise rejection captured',
    //This is occurring in legacy app on accepting invites. Doesn't seem to affect user
    "Failed to execute 'removeChild' on 'Node'",
    // @TODO - remove this once we have a fix for the issue
    "Cannot read properties of undefined (reading 'me')"
  ]
})

function showBrowserError() {
  const match = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./)
  const chromeVersion = match ? parseInt(match[2], 10) : false
  if (chromeVersion && chromeVersion < 81) {
    return true
  }
  return false
}

/* The data that is provided to single-spa routes */
const data = {
  loaders: {
    main: loaderMain
  },
  errors: {
    mainError: showBrowserError() ? errorBrowser : errorDefault
  },
  props: {
    i18n: i18n,
    getPrevRoute: getPrevRoute,
    pusherApiKey: appConfig.pusherApiKey,
    intercomAppId: appConfig.intercomAppId,
    productImagesDomain: appConfig.productImagesDomain,
    recaptchaV3SiteKey: appConfig.recaptchaV3SiteKey
  }
}

PapertrailSession.boot(appConfig.pusherApiKey, appConfig.intercomAppId)

let prevRoute = ''
function getPrevRoute() {
  return prevRoute
}

/* When route changes:
  set the value of `prevRoute` in case it is needed
  check if we need to load the user
  check if we need to load the account/member
*/
window.addEventListener('single-spa:before-routing-event', async (evt: CustomEvent) => {
  try {
    const prevUrl = new URL(evt.detail.oldUrl)
    prevRoute = prevUrl.pathname + prevUrl.search
  } catch {}
  try {
    const newUrl = new URL(evt.detail.newUrl)
    //set user if not set
    if (isSecurePath(newUrl.pathname) && PapertrailSession.getUser() === undefined) {
      //load the details of the current user and set up pusher channels.
      await PapertrailSession.loadUser()
    }
    //handle account change
    await PapertrailSession.setAccountId(getAccountId(newUrl.pathname))
  } catch (e) {
    Sentry.captureException(e)
    evt.detail.cancelNavigation()
  }
})

/** just for google analytics **/
window.addEventListener('single-spa:routing-event', (evt: CustomEvent) => {
  try {
    const url = new URL(evt.detail.newUrl)
    const title = document.title ? document.title.replace('Papertrail | ', '') : 'Papertrail'
    gtag('set', {
      page_path: url.pathname,
      page_title: title
    })
    gtag('event', 'page_view')
    PapertrailSession.intercom.ping()
  } catch {}
})

/* redirect if any api call logged us out */
window.addEventListener('papertrail:unauthenticated', (evt: Event) => {
  if (isSecurePath(document.location.pathname)) {
    PapertrailSession.reset()
    singleSpa.navigateToUrl('/login')
  }
})

/* redirect if the account cannot be loaded */
window.addEventListener('papertrail:account_access_denied', (evt: Event) => {
  singleSpa.navigateToUrl('/accounts')
})

singleSpa.setBootstrapMaxTime(30000)
singleSpa.setMountMaxTime(30000, false)

singleSpa.addErrorHandler((err) => {
  if (singleSpa.getAppStatus(err.appOrParcelName) === singleSpa.LOAD_ERROR) {
    System.delete(System.resolve(err.appOrParcelName))
  }
})

const routes = constructRoutes(microfrontendLayout, data)
const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name)
  }
})
const layoutEngine = constructLayoutEngine({ routes, applications })

applications.forEach(singleSpa.registerApplication)
layoutEngine.activate()

PapertrailSession.getPartner().then(() => {
  singleSpa.start()
})
