/** supply path with leading / */
export const getAccountId = (path: string) => {
  const segments = path.split('/')
  if (
    segments.length >= 3 &&
    segments[1] == 'accounts' &&
    !(segments[2] === 'new-account' || segments[2] === 'new-subscription')
  ) {
    return segments[2]
  } else {
    return undefined
  }
}

export const isSecurePath = (path: string) => {
  const insecureRoutes = [
    '/login',
    '/signup',
    '/invitations',
    '/invitations/oauth-redirect',
    '/unsubscribe',
    '/oauth/redirect',
    '/share'
  ]

  const insecure = insecureRoutes.findIndex((r) => path.startsWith(r))
  return insecure === -1
}
