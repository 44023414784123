// eslint-disable-next-line import/no-named-as-default
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import LocizeBackend from 'i18next-locize-backend'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

//standard options used for both backend configurations
const i18nOptions = {
  fallbackLng: 'en',
  debug: false,
  interpolation: {
    escapeValue: false
  },
  detection: {
    order: ['localStorage', 'navigator'],
    caches: ['localStorage']
  }
}

if (appConfig.ENV === 'production') {
  //serve the language files from /public/locales
  i18n
    .use(LanguageDetector)
    .use(Backend)
    .use(initReactI18next)
    .init({
      ...i18nOptions
    })
} else {
  //serve the language files from the locize hosted service
  i18n
    .use(LanguageDetector)
    .use(LocizeBackend)
    .use(initReactI18next)
    .init({
      ...i18nOptions,
      backend: {
        projectId: 'a9cdc082-53a3-438f-af97-7a97ed8580de',
        apiKey: 'ghfkhgfkhgf',
        version: 'latest',
        referenceLng: 'en'
      }
    })
}

//TODO set the locale for any date related stuff?
//Note that any locales (other than en) that we want to support must be imported in index.tsx
i18n.on('languageChanged', function (lng) {
  console.log('locale changed')
})

export default i18n
